<template>
  <div>
    <nav v-if="active" id="sidebar">
      <template v-for="(item, index) in items">
        <router-link v-if="item.file" :key="index" :to="`/${base}/${item.file}`">
          {{ item.title }}
        </router-link>
        <h2 v-if="!item.file" :key="index">
          {{ item.title }}
        </h2>
      </template>
    </nav>
    <div id="content" :class="{ active }">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'larva.scss';
body {
  overflow-x: hidden;
}
#sidebar h2 {
  opacity: .7;
  color: $dark;
  font-size: 1rem;
  // border-top: 1px solid rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  margin-top: 1rem;
  margin: 1.5rem .3rem .3rem .3rem;
  letter-spacing: 0.06em;
}
#sidebar {
  background: $background-color;
  padding-top: 2rem;
  z-index: 1000;
  position: fixed;
  width: 250px;
  height: 100%;
  overflow-y: auto;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#content {
  width: 100%;
  position: absolute;
  padding: 15px;
  right: 0;
}
#content.active {
  width: calc(100% - 250px);
}
nav {
  padding: .5rem
}
nav a {
  padding: .2rem 0.5rem;
  display: block;
  color: $primary;
}
nav a:hover {
  color: $primary;
  text-decoration: none;
}
nav a.active {
  color: $light;
  background-color: $primary;
}
</style>
<script>
export default {
  props: ['base'],
  data () {
    return {
      active: false,
      items: []
    };
  },
  watch: {
    base () {
      this.fetchMenu();
    }
  },
  mounted () {
    this.fetchMenu();
  },
  methods: {
    fetchMenu () {
      const menu = `/contents/${this.base}/menu.json`;
      this.active = false;
      this.$http.get(menu)
      .then(res => {
        return res.data;
      })
      .then(data => {
        if (Array.isArray(data) && data.length > 1) {
          this.active = true;
          this.items = data;
        } else {
          console.error('invalid menu file recveived', data);
        }
      });
    }
  },
};
</script>

